'use client'

import clsx from 'clsx'
import type { ComponentProps } from 'react'
import { useState } from 'react'
import { Anchor } from '../anchor/anchor'
import styles from './anchor-list-discloure.module.css'

type AnchorListDisclosureProps = {
  links: [string, string][]
  styles?: {
    list?: string
    listItem?: string
    link?: string
    button?: string
  }
  collapseLimit: number
  tagPrefixFallback?: string
  tagPrefixRemove?: string
  tagSection?: string
} & Partial<Pick<ComponentProps<typeof Anchor>, 'variant'>>

export function AnchorListDisclosure({
  collapseLimit,
  variant = 'dark',
  tagPrefixFallback,
  tagPrefixRemove,
  ...props
}: AnchorListDisclosureProps) {
  const [isExpanded, setExpanded] = useState(false)
  const limit = collapseLimit

  return (
    <>
      <ul className={clsx(styles.anchorListDisclosureList, props.styles?.list)}>
        {(props.links || []).map(([href, label], index) => {
          const id = label
            ? label.replace(tagPrefixRemove ?? '', '')
            : tagPrefixFallback
          const isItemHidden = index > limit - 1

          return (
            <li
              className={clsx(
                styles.anchorListDisclosureListItem,
                props.styles?.listItem,
                !isExpanded &&
                  isItemHidden &&
                  styles.anchorListDisclosureItemHidden
              )}
              data-index={index}
              key={href + label}
            >
              <Anchor
                data-tag_item={[id, 'link'].filter(Boolean).join('_')}
                data-tag_section={props.tagSection}
                variant={variant}
                href={href}
                inert={!isExpanded && isItemHidden}
              >
                {label}
              </Anchor>
            </li>
          )
        })}
      </ul>

      <Anchor
        as="button"
        variant={variant}
        data-tag_item={`show_${
          isExpanded ? 'more' : 'less'
        }_${tagPrefixFallback}_link`}
        data-tag_section={props.tagSection}
        data-tid={`show-${isExpanded ? 'less' : 'more'}-button`}
        onClick={() => {
          setExpanded((prev) => !prev)
        }}
      >
        {`View ${isExpanded ? 'Less -' : 'More +'}`}
      </Anchor>
    </>
  )
}
